import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {},
  paper: {},
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
  title: {},
  bottomSection: {},
  input: {
    fontSize: 'initial',
  },
  button: {},
  logo: {
    height: 40,
    [theme.breakpoints.down('sm')]: {
      height: 30,
    },
  },
  orLine: {
    top: 10,
    '& span': {
      display: 'inline-block',
      width: 60,
      backgroundColor: '#ffffff',
    },
  },

  navLink: {
    color: theme.palette.link_primary_color,
    fontSize: '0.9rem',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:visited': {
      color: props => theme.palette.link_primary_color,
    },
    '&:hover': {
      color: props => theme.palette.link_hover_color,
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
