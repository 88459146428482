import React, { useState } from 'react';

export default (defaultValue, minValue, maxValue) => {
  const [value, setValue] = useState(defaultValue);

  const increment = () => {
    if (value < maxValue) {
      setValue(value + 1);
    }
  };

  const decrement = () => {
    if (value > minValue) {
      setValue(value - 1);
    }
  };

  return [value, setValue, increment, decrement];
};
