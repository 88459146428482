import React, { useReducer } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Alert } from '@material-ui/lab';
import useStyles from './ForgotPasswordForm.styles';
import forgotPasswordReducer, { initialState } from './forgotPasswordReducer';
import GoogleLogo from '../../../svg/GoogleLogo.svg';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import { topMenuDataState } from '../../../common/States';
import { useRecoilValue } from 'recoil';
import Typography from '@material-ui/core/Typography';

const ForgotPasswordForm = ({ setScreenToShow }) => {
  const classes = useStyles();

  const topMenuData = useRecoilValue(topMenuDataState);

  const logoImage = topMenuData?.logo_image;

  const [state, dispatch] = useReducer(forgotPasswordReducer, initialState);

  const { email, password, loading, hasError, error, fieldErrors } = state;

  const handleFieldChange = e => {
    dispatch({ type: 'updateField', payload: { field: e.target } });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (email.length === 0) {
      dispatch({ type: 'fieldErrors' });
    } else {
      try {
        dispatch({ type: 'attemptForgotPassword' });
        await Auth.forgotPassword(email);
        dispatch({ type: 'forgotPasswordSuccessful' });
        setScreenToShow('confirmForgotPassword');
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'forgotPasswordError',
          payload: { errorCode: err.code },
        });
      }
    }
  };

  return (
    <>
      <Box>
        <Box px={2} pb={2}>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              className={classes.input}
              variant="filled"
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleFieldChange}
              disabled={loading}
              error={!!fieldErrors.email}
              helperText={fieldErrors.email || null}
            />
            {hasError && (
              <Box my={1}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Box mt={2} mb={2}>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                id="submit"
                disabled={loading}
              >
                Request New Password
              </Button>
            </Box>
          </form>
        </Box>
        {!loading && (
          <Box px={2} className={classes.bottomSection}>
            <Box textAlign="center" my={0} pb={2}>
              <Typography
                className={classes.navLink}
                variant="body2"
                onClick={() => {
                  setScreenToShow('signIn');
                }}
              >
                {'Remember your password? Sign In'}
              </Typography>
            </Box>

            <Box textAlign="center" my={0} pb={2}>
              <Typography
                className={classes.navLink}
                variant="body2"
                onClick={() => {
                  setScreenToShow('signUp');
                }}
              >
                {"Don't have an account? Sign up"}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ForgotPasswordForm;
