/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { RecoilRoot } from 'recoil';
import WebsiteLayout from '../website/layouts/WebsiteLayout';
import SignInLayout from '../website/layouts/SignInLayout';

const chooseLayout = (pathname, children) => {
  if (
    [
      '/',
      '/our-why',
      '/our-story',
      '/our-world',
      '/travel-experts',
      '/privacy-policy',
      '/terms-and-conditions',
    ].includes(pathname)
  ) {
    return <WebsiteLayout>{children}</WebsiteLayout>;
  }

  if (
    [
      '/signin',
      '/signup',
      '/signedin',
      '/signedin/',
      '/signedout',
      '/signedout/',
    ].includes(pathname)
  ) {
    return <SignInLayout>{children}</SignInLayout>;
  }

  return children;
};

const Layout = ({ children, location }) => {
  return (
    <RecoilRoot>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat"
          rel="stylesheet"
        />
        {/* <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&amp;display=swap"
          rel="stylesheet"
        /> */}
      </Helmet>
      {chooseLayout(location.pathname, children)}
    </RecoilRoot>
  );
};
export default Layout;
