import produce from 'immer';

const confirmForgotPasswordReducer = (draft, action) => {
  const { type, payload } = action;
  const { field, errorCode } = payload || {};
  const { email, code, newPassword, fieldErrors } = draft;
  const emptyfieldErrors = {
    email: '',
    code: '',
    newPassword: '',
  };

  switch (type) {
    case 'attemptConfirmForgotPassword':
      draft.loading = true;
      draft.hasError = false;
      draft.error = '';
      draft.fieldErrors = {
        email: '',
        code: '',
        newPassword: '',
      };
      break;
    case 'fieldErrors':
      if (email.length === 0) {
        emptyfieldErrors.email = 'You must provide an email to update password';
      }
      if (code.length === 0) {
        emptyfieldErrors.code = 'Your must provide the code sent to your email';
      }
      if (newPassword.length === 0) {
        emptyfieldErrors.newPassword = 'Your must provide a password';
      }
      draft.fieldErrors = emptyfieldErrors;
      draft.loading = false;
      draft.hasError = false;
      draft.error = '';
      break;
    case 'confirmForgotPasswordSuccesful':
      draft.loading = false;
      draft.hasError = false;
      draft.error = '';
      draft.isForgot = true;
      break;
    case 'confirmForgotPasswordError':
      draft.email = '';
      draft.password = '';
      draft.loading = false;
      draft.hasError = true;
      draft.error =
        errorCodeMappings[errorCode] ||
        'Error while trying to set new password.';
      draft.isConfirmedForgotPassword = false;
      break;
    case 'updateField':
      if (field.value.length !== 0) fieldErrors[field.name] = '';
      draft[field.name] = field.value;
      draft.fieldErrors = fieldErrors;
      draft.hasError = false;
      draft.error = '';
      break;
    case 'resetField':
      draft[field.name] = '';
      break;
    default:
      break;
  }
  return draft;
};

export const initialState = {
  email: '',
  code: '',
  newPassword: '',
  isConfirmedForgotPassword: false,
  loading: false,
  hasError: false,
  error: '',
  fieldErrors: {
    email: '',
    code: '',
    newPassword: '',
  },
};

const errorCodeMappings = {
  UserNotConfirmedException:
    'This account requires email confirmaion. Please check your email to verify your account.',
  PasswordResetRequiredException:
    'Your password needs to be reset. Please click on the forgot password link to reset your password.',
  InvalidParameterException:
    'Cannot reset password for the user as there is no registered/verified email or phone_number',
  UserNotFoundException:
    'The username is not found. Please click on the Sign up link to create an account.',
};

export default produce(confirmForgotPasswordReducer);
