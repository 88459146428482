import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const convertToTheme = themeConfig => {
  if (!themeConfig) {
    return responsiveFontSizes(createMuiTheme());
  }
  const theme = createMuiTheme({
    typography: {
      fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize: 14,
    },
    palette: {
      primary: {
        main: themeConfig.primary_color,
      },
      secondary: {
        main: themeConfig.secondary_color,
      },
      error: {
        main: themeConfig.error_color,
      },
      warning: {
        main: themeConfig.warning_color,
      },
      info: {
        main: themeConfig.info_color,
      },
      success: {
        main: themeConfig.success_color,
      },
      light_text: themeConfig.light_text,
      dark_text: themeConfig.dark_text,
      primary_text: themeConfig.primary_text,
      secondary_text: themeConfig.secondary_text,
      light_background: themeConfig.light_background,
      dark_background: themeConfig.dark_background,
      primary_background: themeConfig.primary_background,
      secondary_background: themeConfig.secondary_background,
      link_primary_color: themeConfig.link_primary_color,
      link_hover_color: themeConfig.link_hover_color,
      nav_link_color: themeConfig.nav_link_color,
      nav_link_hover_color: themeConfig.nav_link_hover_color,
      nav_link_active_color: themeConfig.nav_link_active_color,
    },
  });
  return responsiveFontSizes(theme);
};
export default convertToTheme;
