import React, { useReducer } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { Alert } from '@material-ui/lab';

import useStyles from './SignUpForm.styles';

import signUpReducer, { initialState } from './signUpReducer';

import GoogleLogo from '../../../svg/GoogleLogo.svg';
import FacebookIcon from '@material-ui/icons/Facebook';

import { Auth } from 'aws-amplify';

import { navigate } from 'gatsby';

import { topMenuDataState } from '../../../common/States';
import { useRecoilValue } from 'recoil';

import Typography from '@material-ui/core/Typography';

const SignUpForm = ({ setScreenToShow, accountTypeIndex }) => {
  const classes = useStyles();

  const topMenuData = useRecoilValue(topMenuDataState);

  const logoImage = topMenuData?.logo_image;

  const [state, dispatch] = useReducer(signUpReducer, initialState);

  const {
    email,
    password,
    loading,
    hasError,
    error,
    fieldErrors,
    currentScreen,
  } = state;

  const handleFieldChange = e => {
    dispatch({ type: 'updateField', payload: { field: e.target } });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (email.length === 0 || password.length === 0) {
      dispatch({ type: 'fieldErrors' });
    } else {
      try {
        dispatch({ type: 'attemptSignUp' });
        const signUpResult = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            'custom:accountType':
              accountTypeIndex === 1 ? 'Agent' : 'Traveller', // custom attribute, not standard
          },
        });
        await Auth.signIn(email, password);
        dispatch({ type: 'signUpSuccesful' });
        navigate('/signedin');
      } catch (error) {
        console.error({ error });
        dispatch({ type: 'signUpError', payload: { errorCode: error.code } });
      }
    }
  };

  return (
    <>
      <Box>
        <Box px={2} pb={0}>
          <Box py={1}>
            <Button
              variant="outlined"
              color="default"
              className={classes.button}
              startIcon={<GoogleLogo />}
              fullWidth
              size="large"
              onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
            >
              Sign up with Google
            </Button>
          </Box>

          <Box py={1}>
            <Button
              variant="outlined"
              color="default"
              className={classes.button}
              startIcon={
                <FacebookIcon htmlColor="#648dae" style={{ fontSize: 28 }} />
              }
              fullWidth
              size="large"
              onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
            >
              Sign up with Facebook
            </Button>
          </Box>
          <Box
            textAlign="center"
            position="relative"
            className={classes.orLine}
          >
            <span>OR</span>
          </Box>
          <hr />

          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              className={classes.input}
              variant="filled"
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleFieldChange}
              disabled={loading}
              error={!!fieldErrors.email}
              helperText={fieldErrors.email || null}
            />
            <TextField
              className={classes.input}
              variant="filled"
              margin="dense"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handleFieldChange}
              disabled={loading}
              error={!!fieldErrors.password}
              helperText={fieldErrors.password || null}
            />
            {hasError && (
              <Box my={1}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Box mt={2} mb={0}>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                id="submit"
                disabled={loading}
              >
                Sign Up
              </Button>
            </Box>
          </form>
        </Box>

        <Box textAlign="center" my={0} py={4} className={classes.bottomSection}>
          {!loading && (
            <Typography
              className={classes.navLink}
              variant="body2"
              onClick={() => {
                setScreenToShow('signIn');
              }}
            >
              {'Already have an account? Sign in'}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SignUpForm;
