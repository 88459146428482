import React, { useState } from 'react';

export default defaultValues => {
  const [values, setValues] = useState(defaultValues);

  const setCheckedValue = checkBoxEvent => {
    if (checkBoxEvent.target) {
      setValues({
        ...values,
        [checkBoxEvent.target.name]: checkBoxEvent.target.checked,
      });
    }
  };

  return [values, setValues, setCheckedValue];
};
