import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { useRecoilValue } from 'recoil';
import {
  themeConfigState,
  seoDataState,
  topMenuDataState,
  footerDataState,
} from '../../common/States';
import convertToTheme from '../../common/ThemeConverter';
import SEO from '../components/SEO';
import MenuBar from '../components/MenuBar';
import Footer from '../components/Footer';
import SignInDialog from '../components/SignInForm/SignInDialog';
import NewEnquiryDialog from '../components/NewEnquiryDialog';

const WebsiteLayout = ({ children }) => {
  const themeConfig = useRecoilValue(themeConfigState);
  const seoData = useRecoilValue(seoDataState);
  const topMenuData = useRecoilValue(topMenuDataState);
  const footerData = useRecoilValue(footerDataState);

  return (
    <>
      <ThemeProvider theme={convertToTheme(themeConfig)}>
        <CssBaseline />
        <SEO title={seoData?.title} desc={seoData?.description} />
        <MenuBar data={topMenuData} />
        {children}
        <Footer data={footerData} />
        <SignInDialog />
        <NewEnquiryDialog />
        <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/20015178.js"></script>
      </ThemeProvider>
    </>
  );
};
export default WebsiteLayout;
