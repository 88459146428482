import React, { useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import useInputState from '../../common/hooks/useInputState';
import useIncrementState from '../../common/hooks/useIncrementState';
import useCheckedListState from '../../common/hooks/useCheckedListState';
import useInputValidation from '../../common/hooks/useInputValidation';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Cache } from 'aws-amplify';
import Alert from '@material-ui/lab/Alert';

import {
  pauseAnimationsState,
  newEnquiryDialogOpenState,
  signInDialogOpenState,
} from '../../common/States';
import { useSetRecoilState, useRecoilState } from 'recoil';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    flexWrap: 'wrap',
  },
  paxLabels: {
    flexGrow: 1,
  },
  adornments: {
    alignItems: 'end',
    marginBottom: 5,
  },
}));

const enquiryForLabels = {
  accommodation: 'Accommodation',
  flights: 'Flights',
  transfers: 'Transfers',
  activities: 'Activities',
  tours: 'Tours',
  cruises: 'Cruises',
  carHire: 'Car Hire',
  rail: 'Rail',
  other: 'Other',
};

function getSteps() {
  return ['Travel Details', 'Passengers', 'Included in Budget'];
}

const createEnquiryForText = enquiryForValues => {
  const valuesSelected = Object.keys(enquiryForValues).filter(key => {
    return enquiryForValues[key];
  });
  const labels = valuesSelected.map(key => {
    return enquiryForLabels[key];
  });
  if (labels.length === 0) {
    return 'Not specified';
  }
  if (labels.length === 1) {
    return labels[0];
  }

  return (
    labels.splice(0, labels.length - 1).join(', ') +
    ' and ' +
    labels[labels.length - 1]
  );
};

const getEnquiryForArray = enquiryForValues => {
  const valuesSelected = Object.keys(enquiryForValues).filter(key => {
    return enquiryForValues[key];
  });
  const labels = valuesSelected.map(key => {
    return enquiryForLabels[key];
  });
  return labels;
};

const atLeastOneEnquiryForChecked = enquiryForValues => {
  const valuesSelected = Object.keys(enquiryForValues).filter(key => {
    return enquiryForValues[key];
  });
  return valuesSelected.length > 0;
};

function StepContent({
  stepIndex,
  departingFrom,
  handleDepartingFromUpdate,
  destinations,
  handleDestinationsUpdate,
  tripLength,
  handleTripLengthUpdate,
  nickName,
  handleNickNameUpdate,
  budget,
  handleBudgetUpdate,
  adults,
  incrementAdults,
  decrementAdults,
  children,
  incrementChildren,
  decrementChildren,
  infants,
  incrementInfants,
  decrementInfants,
  enquiryForValues,
  setEnquiryForCheckedValue,
  isNickNameValid,
  nickNameValidationMessage,
  nickNameValidation,
  isDepartingFromValid,
  departingFromValidationMessage,
  departingFromValidation,
  isDestinationsValid,
  destinationsValidationMessage,
  destinationsValidation,
  isTripLengthValid,
  tripLengthValidationMessage,
  tripLengthValidation,
  isBudgetValid,
  budgetValidationMessage,
  budgetValidation,
}) {
  const classes = useStyles();
  switch (stepIndex) {
    case 0:
      return (
        <Box mb={4}>
          <Box mt={2}>
            <Typography className={classes.instructions}>
              Give your enquiry a nick name:
            </Typography>
            <TextField
              value={nickName}
              margin="dense"
              onChange={handleNickNameUpdate}
              variant="filled"
              autoFocus
              onBlur={nickNameValidation}
              error={!isNickNameValid}
              helperText={nickNameValidationMessage}
              fullWidth
            />
          </Box>

          <Box mt={2}>
            <Typography className={classes.instructions}>
              Where are you departing from?
            </Typography>
            <TextField
              value={departingFrom}
              onChange={handleDepartingFromUpdate}
              variant="filled"
              margin="dense"
              fullWidth
              onBlur={departingFromValidation}
              error={!isDepartingFromValid}
              helperText={departingFromValidationMessage}
            />
          </Box>
          <Box mt={2}>
            <Typography className={classes.instructions}>
              Destination(s) for your trip:
            </Typography>
            <TextField
              value={destinations}
              onChange={handleDestinationsUpdate}
              variant="filled"
              margin="dense"
              fullWidth
              onBlur={destinationsValidation}
              error={!isDestinationsValid}
              helperText={destinationsValidationMessage}
            />
          </Box>
          <Box mt={2}>
            <Typography className={classes.instructions}>
              How long is your trip?
            </Typography>
            <FormControl fullWidth variant="filled" error={!isTripLengthValid}>
              <FilledInput
                value={tripLength}
                onChange={handleTripLengthUpdate}
                onBlur={tripLengthValidation}
                margin="dense"
                endAdornment={
                  <InputAdornment className={classes.adornments} position="end">
                    Days
                  </InputAdornment>
                }
              />
              <FormHelperText>{tripLengthValidationMessage}</FormHelperText>
            </FormControl>
          </Box>
        </Box>
      );
    case 1:
      return (
        <Box mb={4}>
          <Typography className={classes.instructions}>
            How many adults and children are going on this trip?
          </Typography>
          <Box mt={2}>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                textAlign="center"
                flexDirection="column"
                className={classes.paxLabels}
              >
                <Typography variant="h6">Adults</Typography>
                <Typography variant="body2">Aged 12 years and above</Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                minWidth={125}
              >
                <IconButton aria-label="minus" onClick={decrementAdults}>
                  <RemoveIcon />
                </IconButton>
                <Typography variant="h4">{adults}</Typography>
                <IconButton aria-label="plus" onClick={incrementAdults}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                textAlign="center"
                flexDirection="column"
                className={classes.paxLabels}
              >
                <Typography variant="h6">Children</Typography>
                <Typography variant="body2">
                  Aged between 2 and 11 years
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                minWidth={125}
              >
                <IconButton aria-label="minus" onClick={decrementChildren}>
                  <RemoveIcon />
                </IconButton>
                <Typography variant="h4">{children}</Typography>
                <IconButton aria-label="plus" onClick={incrementChildren}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                textAlign="center"
                flexDirection="column"
                className={classes.paxLabels}
              >
                <Typography variant="h6">Infants</Typography>
                <Typography variant="body2">
                  Aged between 0 and 23 months
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                minWidth={125}
              >
                <IconButton aria-label="minus" onClick={decrementInfants}>
                  <RemoveIcon />
                </IconButton>
                <Typography variant="h4">{infants}</Typography>
                <IconButton aria-label="plus" onClick={incrementInfants}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    case 2:
      return (
        <Box mb={4}>
          <Box mt={2}>
            <Typography className={classes.instructions}>
              What things do you want help booking for your holiday?
            </Typography>
          </Box>
          <Box maxWidth={400}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enquiryForValues.accommodation}
                    onChange={setEnquiryForCheckedValue}
                    name="accommodation"
                    color="primary"
                  />
                }
                label={enquiryForLabels.accommodation}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enquiryForValues.flights}
                    onChange={setEnquiryForCheckedValue}
                    name="flights"
                    color="primary"
                  />
                }
                label={enquiryForLabels.flights}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enquiryForValues.transfers}
                    onChange={setEnquiryForCheckedValue}
                    name="transfers"
                    color="primary"
                  />
                }
                label={enquiryForLabels.transfers}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enquiryForValues.activities}
                    onChange={setEnquiryForCheckedValue}
                    name="activities"
                    color="primary"
                  />
                }
                label={enquiryForLabels.activities}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enquiryForValues.tours}
                    onChange={setEnquiryForCheckedValue}
                    name="tours"
                    color="primary"
                  />
                }
                label={enquiryForLabels.tours}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enquiryForValues.cruises}
                    onChange={setEnquiryForCheckedValue}
                    name="cruises"
                    color="primary"
                  />
                }
                label={enquiryForLabels.cruises}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enquiryForValues.carHire}
                    onChange={setEnquiryForCheckedValue}
                    name="carHire"
                    color="primary"
                  />
                }
                label={enquiryForLabels.carHire}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enquiryForValues.rail}
                    onChange={setEnquiryForCheckedValue}
                    name="rail"
                    color="primary"
                  />
                }
                label={enquiryForLabels.rail}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enquiryForValues.other}
                    onChange={setEnquiryForCheckedValue}
                    name="other"
                    color="primary"
                  />
                }
                label={enquiryForLabels.other}
              />
            </FormGroup>
            {!atLeastOneEnquiryForChecked(enquiryForValues) && (
              <FormHelperText error>
                Please selected at least one option.
              </FormHelperText>
            )}
          </Box>
          <Box mt={4}>
            <Typography className={classes.instructions}>
              Approximately how much do you want to spend?
            </Typography>
            <FormControl fullWidth variant="filled" error={!isBudgetValid}>
              <FilledInput
                value={budget}
                onChange={handleBudgetUpdate}
                onBlur={budgetValidation}
                margin="dense"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                endAdornment={
                  <InputAdornment className={classes.adornments} position="end">
                    AUD
                  </InputAdornment>
                }
              />
              <FormHelperText>{budgetValidationMessage}</FormHelperText>
            </FormControl>
          </Box>
        </Box>
      );
    default:
      return (
        <Typography className={classes.instructions}>
          Unknown stepIndex
        </Typography>
      );
  }
}

export default () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const setPauseAnimations = useSetRecoilState(pauseAnimationsState);

  const [newEnquiryDialogOpen, setNewEnquiryDialogOpen] = useRecoilState(
    newEnquiryDialogOpenState
  );

  const setSignInDialogOpen = useSetRecoilState(signInDialogOpenState);
  const [
    enquiryForValues,
    setEnquiryForValues,
    setEnquiryForCheckedValue,
  ] = useCheckedListState({
    accommodation: false,
    flights: false,
    transfers: false,
    activities: false,
    tours: false,
    cruises: false,
    carHire: false,
    rail: false,
    other: false,
  });
  const [
    departingFrom,
    setDepartingFrom,
    handleDepartingFromUpdate,
  ] = useInputState('');
  const [
    destinations,
    setDestinations,
    handleDestinationsUpdate,
  ] = useInputState('');
  const [tripLength, setTripLength, handleTripLengthUpdate] = useInputState('');
  const [nickName, setNickName, handleNickNameUpdate] = useInputState('');
  const [budget, setBudget, handleBudgetUpdate] = useInputState('');
  const [
    adults,
    setAdults,
    incrementAdults,
    decrementAdults,
  ] = useIncrementState(1, 1, 25);
  const [
    children,
    setChildren,
    incrementChildren,
    decrementChildren,
  ] = useIncrementState(0, 0, 25);
  const [
    infants,
    setInfants,
    incrementInfants,
    decrementInfants,
  ] = useIncrementState(0, 0, 25);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const [loading, setLoading] = useState(false);

  const handleNext = useCallback(() => {
    if (activeStep === steps.length) {
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }, [activeStep]);

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, [activeStep]);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const newEnquiry = {
        owner: null,
        nickName,
        departingFrom,
        destinations,
        tripLength: parseInt(tripLength),
        budget: parseInt(budget),
        adults: parseInt(adults),
        children: parseInt(children),
        infants: parseInt(infants),
        enquireFor: getEnquiryForArray(enquiryForValues),
        listingStatus: 'NOT LISTED',
      };
      Cache.setItem('newEnquiry', newEnquiry);
      setLoading(false);
      setNewEnquiryDialogOpen(false);
      setSignInDialogOpen(true);
    } catch (error) {
      console.error({ error });
    }
  };

  const handleReset = useCallback(() => {
    setActiveStep(0);
    setEnquiryForValues({
      accommodation: false,
      flights: false,
      transfers: false,
      activities: false,
      tours: false,
      cruises: false,
      carHire: false,
      rail: false,
      other: false,
    });
    setDepartingFrom('');
    setDestinations('');
    setTripLength('');
    setNickName('');
    setBudget('');
    setAdults(1);
    setChildren(0);
    setInfants(0);
    resetNickNameValidation();
    resetDepartingFromValidation();
    resetDestinationValidation();
    resetTripLengthValidation();
    resetBudgetValidation();
  }, []);

  useEffect(() => {
    if (!newEnquiryDialogOpen) {
      Cache.removeItem('newEnquiry');
      handleReset();
    }
  }, [newEnquiryDialogOpen]);

  const [
    isNickNameValid,
    nickNameValidationMessage,
    nickNameValidation,
    resetNickNameValidation,
  ] = useInputValidation(value => {
    if (value.length === 0) {
      return [
        false,
        "Nick name is required. For example 'Family trip to Paris'.",
      ];
    } else {
      return [true, ''];
    }
  });
  const [
    isDepartingFromValid,
    departingFromValidationMessage,
    departingFromValidation,
    resetDepartingFromValidation,
  ] = useInputValidation(value => {
    if (value.length === 0) {
      return [false, 'This is a required field.'];
    } else {
      return [true, ''];
    }
  });
  const [
    isDestinationsValid,
    destinationsValidationMessage,
    destinationsValidation,
    resetDestinationValidation,
  ] = useInputValidation(value => {
    if (value.length === 0) {
      return [false, 'This is a required field.'];
    } else {
      return [true, ''];
    }
  });
  const [
    isTripLengthValid,
    tripLengthValidationMessage,
    tripLengthValidation,
    resetTripLengthValidation,
  ] = useInputValidation(value => {
    if (value.length === 0) {
      return [false, 'This is a required field.'];
    }
    if (isNaN(parseInt(value))) {
      return [false, 'Trip length must be a number.'];
    }
    if (parseInt(value) <= 0) {
      return [false, 'Trip length must be a positive number.'];
    }

    return [true, ''];
  });

  const [
    isBudgetValid,
    budgetValidationMessage,
    budgetValidation,
    resetBudgetValidation,
  ] = useInputValidation(value => {
    if (value.length === 0) {
      return [true, ''];
    }
    if (isNaN(parseInt(value))) {
      return [false, 'Budget must be a number.'];
    }
    if (parseInt(value) <= 0) {
      return [false, 'Budget must be a positive number.'];
    }

    return [true, ''];
  });

  const handleClose = useCallback(() => {
    setPauseAnimations(false);
    setNewEnquiryDialogOpen(false);
    Cache.removeItem('newEnquiry');
  }, []);

  useEffect(() => {
    setPauseAnimations(newEnquiryDialogOpen);
  }, [newEnquiryDialogOpen]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={newEnquiryDialogOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {loading && <LinearProgress />}
      <DialogTitle id="responsive-dialog-title">
        {activeStep === 0 ? 'New Enquiry' : nickName}
      </DialogTitle>
      <DialogContent>
        <Stepper
          className={classes.stepper}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === steps.length ? (
          <Box mb={4}>
            <Box mt={2}>
              <Typography variant="body1" className={classes.instructions}>
                Travel Details
              </Typography>
              <Typography variant="body2" className={classes.instructions}>
                Departing from:{' '}
                {departingFrom ? departingFrom : 'Not specified'}
              </Typography>
              <Typography variant="body2" className={classes.instructions}>
                Destination(s): {destinations ? destinations : 'Not specified'}
              </Typography>
              <Typography variant="body2" className={classes.instructions}>
                Trip length:{' '}
                {tripLength ? tripLength + ' days' : 'Not specified'}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body1" className={classes.instructions}>
                Passengers
              </Typography>
              <Typography variant="body2" className={classes.instructions}>
                Adults: {adults}, Children: {children}, Infants: {infants}.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body1" className={classes.instructions}>
                Included in Budget
              </Typography>
              <Typography variant="body2" className={classes.instructions}>
                Include: {createEnquiryForText(enquiryForValues)}
              </Typography>
              <Typography variant="body2" className={classes.instructions}>
                Budget: {budget ? '$' + budget + ' AUD' : 'Not specified'}
              </Typography>
            </Box>
            <Box pt={2}>
              <Alert severity="info">
                To list your enquiry, you must first Sign In.
              </Alert>
            </Box>
          </Box>
        ) : (
          <Box>
            <StepContent
              departingFrom={departingFrom}
              handleDepartingFromUpdate={handleDepartingFromUpdate}
              destinations={destinations}
              handleDestinationsUpdate={handleDestinationsUpdate}
              tripLength={tripLength}
              handleTripLengthUpdate={handleTripLengthUpdate}
              nickName={nickName}
              handleNickNameUpdate={handleNickNameUpdate}
              budget={budget}
              handleBudgetUpdate={handleBudgetUpdate}
              adults={adults}
              incrementAdults={incrementAdults}
              decrementAdults={decrementAdults}
              children={children}
              incrementChildren={incrementChildren}
              decrementChildren={decrementChildren}
              infants={infants}
              incrementInfants={incrementInfants}
              decrementInfants={decrementInfants}
              enquiryForValues={enquiryForValues}
              setEnquiryForCheckedValue={setEnquiryForCheckedValue}
              stepIndex={activeStep}
              isNickNameValid={isNickNameValid}
              nickNameValidationMessage={nickNameValidationMessage}
              nickNameValidation={nickNameValidation}
              isDepartingFromValid={isDepartingFromValid}
              departingFromValidationMessage={departingFromValidationMessage}
              departingFromValidation={departingFromValidation}
              isDestinationsValid={isDestinationsValid}
              destinationsValidationMessage={destinationsValidationMessage}
              destinationsValidation={destinationsValidation}
              isTripLengthValid={isTripLengthValid}
              tripLengthValidationMessage={tripLengthValidationMessage}
              tripLengthValidation={tripLengthValidation}
              isBudgetValid={isBudgetValid}
              budgetValidationMessage={budgetValidationMessage}
              budgetValidation={budgetValidation}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleClose}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          disabled={activeStep === 0 || loading}
          onClick={handleBack}
          className={classes.backButton}
        >
          Back
        </Button>
        {activeStep === 0 && (
          <Button
            disabled={
              !isNickNameValid ||
              !isDepartingFromValid ||
              !isDestinationsValid ||
              !isTripLengthValid ||
              nickName.length === 0 ||
              departingFrom.length === 0 ||
              destinations.length === 0 ||
              tripLength.length === 0
            }
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            Next
          </Button>
        )}
        {activeStep > 0 && activeStep < steps.length - 1 && (
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <Button
            disabled={
              !isBudgetValid ||
              !atLeastOneEnquiryForChecked(enquiryForValues) ||
              loading
            }
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            View Summary
          </Button>
        )}
        {activeStep === steps.length && (
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            {loading ? 'Saving...' : 'Sign In'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
