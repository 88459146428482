import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ConfirmForgotPasswordForm from './ConfirmForgotPasswordForm';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Cache } from 'aws-amplify';
import {
  pauseAnimationsState,
  signInDialogOpenState,
} from '../../../common/States';
import { useSetRecoilState, useRecoilState } from 'recoil';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: theme.spacing(1),
    paddingBottom: 0,
    paddingTop: theme.spacing(2),
  },
  dialogTitleText: {
    margin: 0,
  },
  dialogCloseButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  dialogContent: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 400,
    },
  },
}));

const SignInDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [screenToShow, setScreenToShow] = useState('signUp');
  const [loading, setLoading] = useState(false);
  const [tabSelection, setTabSection] = React.useState(0);

  const handleTabChange = useCallback((event, newValue) => {
    setTabSection(newValue);
  }, []);

  const setPauseAnimations = useSetRecoilState(pauseAnimationsState);

  const [signInDialogOpen, setSignInDialogOpen] = useRecoilState(
    signInDialogOpenState
  );

  const handleCancel = useCallback(() => {
    setPauseAnimations(false);
    setSignInDialogOpen(false);
    setScreenToShow('signIn');
    Cache.removeItem('newEnquiry');
  }, []);

  useEffect(() => {
    setPauseAnimations(signInDialogOpen);
  }, [signInDialogOpen]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={signInDialogOpen}
      onClose={handleCancel}
      aria-labelledby="responsive-dialog-title"
    >
      {loading && <LinearProgress />}
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {screenToShow === 'signIn' && (
            <h4 className={classes.dialogTitleText}>Sign In</h4>
          )}
          {screenToShow === 'signUp' && (
            <h4 className={classes.dialogTitleText}>Sign Up</h4>
          )}
          {screenToShow === 'forgotPassword' && (
            <h4 className={classes.dialogTitleText}>Forgot Password</h4>
          )}
          {screenToShow === 'confirmForgotPassword' && (
            <h4 className={classes.dialogTitleText}>Confirm Forgot Password</h4>
          )}
          <IconButton
            onClick={handleCancel}
            size="small"
            aria-label="close"
            color="default"
            className={classes.dialogCloseButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box py={2} display="flex" justifyContent="center">
          <Tabs
            value={tabSelection}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Traveller" />
            <Tab label="Travel Expert" />
          </Tabs>
        </Box>

        {screenToShow === 'signIn' && (
          <SignInForm setScreenToShow={setScreenToShow} />
        )}
        {screenToShow === 'signUp' && (
          <SignUpForm
            setScreenToShow={setScreenToShow}
            accountTypeIndex={tabSelection}
          />
        )}
        {screenToShow === 'forgotPassword' && (
          <ForgotPasswordForm
            setScreenToShow={setScreenToShow}
            isFullVersion={true}
          />
        )}
        {screenToShow === 'confirmForgotPassword' && (
          <ConfirmForgotPasswordForm
            setScreenToShow={setScreenToShow}
            isFullVersion={true}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SignInDialog;
