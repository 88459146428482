import React, { useState, useCallback } from 'react';

export default validation => {
  const [isValid, setIsValid] = useState(true);
  const [message, setMessage] = useState('');

  const validationMethod = useCallback(e => {
    const [isValidResponse, validationMessageResponse] = validation(
      e.target.value
    );
    setIsValid(isValidResponse);
    setMessage(validationMessageResponse);
  });

  const reset = useCallback(() => {
    setIsValid(true);
    setMessage('');
  }, []);

  return [isValid, message, validationMethod, reset];
};
