import React, { useReducer } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Alert } from '@material-ui/lab';
import useStyles from './ConfirmForgotPasswordForm.styles';
import confirmForgotPasswordReducer, {
  initialState,
} from './confirmForgotPasswordReducer';
import GoogleLogo from '../../../svg/GoogleLogo.svg';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import { topMenuDataState } from '../../../common/States';
import { useRecoilValue } from 'recoil';
import Typography from '@material-ui/core/Typography';

const ConfirmForgotPasswordForm = ({ setScreenToShow }) => {
  const classes = useStyles();

  const topMenuData = useRecoilValue(topMenuDataState);

  const logoImage = topMenuData?.logo_image;

  const [state, dispatch] = useReducer(
    confirmForgotPasswordReducer,
    initialState
  );

  const {
    email,
    code,
    newPassword,
    loading,
    hasError,
    error,
    fieldErrors,
  } = state;

  const handleFieldChange = e => {
    dispatch({ type: 'updateField', payload: { field: e.target } });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (email.length === 0 || code.length === 0 || newPassword.length === 0) {
      dispatch({ type: 'fieldErrors' });
    } else {
      try {
        dispatch({ type: 'attemptConfirmForgotPassword' });
        await Auth.forgotPasswordSubmit(email, code, newPassword);
        dispatch({ type: 'confirmForgotPasswordSuccessful' });
        navigate('/signedin');
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'confirmForgotPasswordError',
          payload: { errorCode: err.code },
        });
      }
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box>
        <Box px={2}>
          <Alert severity="info">
            Please check your email and enter to code provided to update your
            password.
          </Alert>
        </Box>
        <Box px={2} pb={1}>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              className={classes.input}
              variant="filled"
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleFieldChange}
              disabled={loading}
              error={!!fieldErrors.email}
              helperText={fieldErrors.email || null}
            />
            <TextField
              className={classes.input}
              variant="filled"
              margin="dense"
              required
              fullWidth
              name="code"
              label="Code"
              type="text"
              id="code"
              autoComplete="code"
              value={code}
              onChange={handleFieldChange}
              disabled={loading}
              error={!!fieldErrors.code}
              helperText={fieldErrors.code || null}
            />
            <TextField
              className={classes.input}
              variant="filled"
              margin="dense"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              value={newPassword}
              onChange={handleFieldChange}
              disabled={loading}
              error={!!fieldErrors.newPassword}
              helperText={fieldErrors.newPassword || null}
            />
            {hasError && (
              <Box my={1}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Box mt={2} mb={2}>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                id="submit"
                disabled={loading}
              >
                Update Password
              </Button>
            </Box>
          </form>
        </Box>
        {!loading && (
          <Box px={2} className={classes.bottomSection}>
            <Box textAlign="center" my={0} pb={2}>
              <Typography
                className={classes.navLink}
                variant="body2"
                onClick={() => {
                  setScreenToShow('signIn');
                }}
              >
                {'Remember your password? Sign In'}
              </Typography>
            </Box>

            <Box textAlign="center" my={0} pb={2}>
              <Typography
                className={classes.navLink}
                variant="body2"
                onClick={() => {
                  setScreenToShow('signUp');
                }}
              >
                {"Don't have an account? Sign up"}
              </Typography>
            </Box>
            <Box textAlign="center" pb={2}>
              <Typography
                className={classes.navLink}
                variant="body2"
                onClick={() => {
                  setScreenToShow('forgotPassword');
                }}
              >
                Resend forgot password code
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ConfirmForgotPasswordForm;
