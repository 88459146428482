import produce from 'immer';

const signUpReducer = (draft, action) => {
  const { type, payload } = action;
  const { field, errorCode } = payload || {};
  const { email, password, fieldErrors, currentScreen } = draft;
  const emptyfieldErrors = {
    email: '',
    password: '',
  };

  switch (type) {
    case 'attemptSignUp':
      draft.loading = true;
      draft.hasError = false;
      draft.error = '';
      draft.fieldErrors = {
        email: '',
        password: '',
      };
      break;
    case 'fieldErrors':
      if (email.length === 0) {
        emptyfieldErrors.email = 'You must provide an email to Sign up';
      }
      if (password.length === 0) {
        emptyfieldErrors.password = 'Your must provide a password to Sign up';
      }
      draft.fieldErrors = emptyfieldErrors;
      draft.loading = false;
      draft.hasError = false;
      draft.error = '';
      break;
    case 'signUpSuccesful':
      draft.loading = false;
      draft.hasError = false;
      draft.error = '';
      draft.isSignedUp = true;
      break;
    case 'signUpError':
      draft.email = '';
      draft.password = '';
      draft.loading = false;
      draft.hasError = true;
      draft.error = errorCodeMappings[errorCode] || 'Error on Sign up.';
      draft.isSignedIn = false;
      break;
    case 'updateField':
      if (field.value.length !== 0) fieldErrors[field.name] = '';
      draft[field.name] = field.value;
      draft.fieldErrors = fieldErrors;
      draft.hasError = false;
      draft.error = '';
      break;
    case 'resetField':
      draft[field.name] = '';
      break;
    default:
      break;
  }
  return draft;
};

export const initialState = {
  email: '',
  password: '',
  isSignedUp: false,
  loading: false,
  hasError: false,
  error: '',
  currentScreen: 'signUp',
  fieldErrors: {
    email: '',
    password: '',
  },
};

const errorCodeMappings = {
  UsernameExistsException: 'An account with the given email already exists.',
};

export default produce(signUpReducer);
