import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { useRecoilValue } from 'recoil';
import { themeConfigState, seoDataState } from '../../common/States';
import convertToTheme from '../../common/ThemeConverter';
import SEO from '../components/SEO';

const SignInLayout = ({ children }) => {
  const themeConfig = useRecoilValue(themeConfigState);
  const seoData = useRecoilValue(seoDataState);

  return (
    <>
      <ThemeProvider theme={convertToTheme(themeConfig)}>
        <CssBaseline />
        <SEO title={seoData?.title} desc={seoData?.description} />
        {children}
      </ThemeProvider>
    </>
  );
};
export default SignInLayout;
