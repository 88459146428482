import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { signInDialogOpenState } from '../../common/States';
import { useSetRecoilState } from 'recoil';

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  appbar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },

  title: {
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      flexGrow: 0,
      marginRight: theme.spacing(3),
    },
  },

  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  menuItem: {
    marginTop: 4,
    marginBottom: 4,
  },
  logo: {
    height: 40,
    [theme.breakpoints.down('sm')]: {
      height: 30,
      marginTop: 5,
      marginBottom: 5,
    },
  },

  navLink: {
    color: props => theme.palette[props.nav_links_color],
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    fontSize: '0.9rem',
    '&:hover': {
      color: props => theme.palette[props.nav_links_hover_color],
    },
  },
  sideNavLink: {
    width: '100%',
    textAlign: 'center',
    fontSize: '0.9rem',
    color: props => theme.palette[props.nav_links_color],
    '&:hover': {
      color: props => theme.palette[props.nav_links_hover_color],
    },
  },
  activeNavLink: {
    color: props => theme.palette[props.nav_links_active_color],
  },

  menuBar: {
    position: 'sticky',
    top: 0,
  },
  toolbar: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 50,
      marginRight: 50,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 100,
      marginRight: 100,
    },
  },
}));

const LinkOrDialogButton = ({ label, href, size, variant, color, classes }) => {
  const setSignInDialogOpen = useSetRecoilState(signInDialogOpenState);
  switch (href) {
    case 'signin':
      return (
        <Button
          onClick={() => {
            setSignInDialogOpen(true);
          }}
          size={size}
          variant={variant}
          color={color}
          className={classes.menuButton}
        >
          {label}
        </Button>
      );
    default:
      return (
        <Button
          component={AniLink}
          fade
          duration={1}
          to={`/${href ? href : ''}`}
          size={size}
          variant={variant}
          color={color}
          className={classes.menuButton}
        >
          {label}
        </Button>
      );
  }
};

export default React.memo(({ data }) => {
  if (!data) return null;

  const setSignInDialogOpen = useSetRecoilState(signInDialogOpenState);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen(isOpen => !isOpen);
  };

  const {
    nav_links_color,
    nav_links_hover_color,
    nav_links_active_color,
  } = data;
  const classes = useStyles({
    nav_links_color,
    nav_links_hover_color,
    nav_links_active_color,
  });
  const logoImage = data.logo_image;
  const navigationLinks = data.navigation_links;
  const menuButtons = data.menu_buttons;
  return (
    <>
      <AppBar className={classes.appbar} color={data.color} position="sticky">
        <Toolbar className={classes.toolbar}>
          <Box width="100%">
            <Box display="flex" alignItems="center" width="100%">
              <Box display={{ sx: 'block', md: 'none' }}>
                <IconButton
                  edge="start"
                  className={classes.burgerButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box display="flex" className={classes.title}>
                <AniLink fade duration={1} to="/">
                  <img
                    className={classes.logo}
                    src={logoImage.url}
                    alt={logoImage.alt}
                  />
                </AniLink>
              </Box>
              <Box
                display={{ xs: 'none', md: 'flex' }}
                justifyContent="flex-start"
                alignItems="center"
                textAlign="center"
                flexGrow={1}
              >
                {navigationLinks.map((item, index) => {
                  const href = item.link._meta?.uid;
                  return (
                    <Link
                      className={classes.navLink}
                      component={AniLink}
                      fade
                      duration={1}
                      key={index}
                      to={`/${href ? href : ''}`}
                      variant={item.variant}
                      underline={item.underline}
                      activeClassName={classes.activeNavLink}
                    >
                      {item.text}
                    </Link>
                  );
                })}
              </Box>
              <Box display={{ xs: 'none', md: 'flex' }}>
                <Button
                  onClick={() => {
                    setSignInDialogOpen(true);
                  }}
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.menuButton}
                >
                  Sign Up
                </Button>
              </Box>

              <Box display={{ sx: 'block', md: 'none' }}>
                <IconButton
                  edge="end"
                  className={classes.personButton}
                  color="inherit"
                  aria-label="sign-in"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    setSignInDialogOpen(true);
                  }}
                >
                  <PersonIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar className={classes.toolbar}>
          <Box width="100%">
            <Box display="flex" justifyContent="center">
              <img
                className={classes.logo}
                src={logoImage.url}
                alt={logoImage.alt}
              />
            </Box>
          </Box>
        </Toolbar>{' '}
        <div className={classes.drawerContainer}>
          <List>
            {navigationLinks.map((item, index) => {
              const href = item.link._meta?.uid;
              return (
                <ListItem button key={index}>
                  <Link
                    className={classes.sideNavLink}
                    component={AniLink}
                    fade
                    duration={1}
                    key={index}
                    to={`/${href ? href : ''}`}
                    onClick={handleDrawerClose}
                    variant={item.variant}
                    underline={item.underline}
                    activeClassName={classes.activeNavLink}
                  >
                    {item.text}
                  </Link>
                </ListItem>
              );
            })}
          </List>
        </div>
      </Drawer>
    </>
  );
});
