import { selector, atom } from 'recoil';
// import { UserProfile } from '../models';
import { Auth, DataStore } from 'aws-amplify';

const currentUserState = atom({
  key: 'currentUser',
  default: null,
});

const themeConfigState = atom({
  key: 'themePropertiesState',
  default: null,
});

const travellerSideMenuOpenState = atom({
  key: 'travellerSideMenuOpenState',
  default: true,
});

const agentSideMenuOpenState = atom({
  key: 'agentSideMenuOpenState',
  default: true,
});

const signInDialogOpenState = atom({
  key: 'signInDialogOpenState',
  default: false,
});

const newEnquiryDialogOpenState = atom({
  key: 'newEnquiryDialogOpenState',
  default: false,
});

const pauseAnimationsState = atom({
  key: 'pauseAnimationsState',
  default: false,
});

const travellerBreadCrumbDataState = atom({
  key: 'travellerBreadCrumbDataState',
  default: { title: '', breadcrumbs: [] },
});

const agentBreadCrumbDataState = atom({
  key: 'agentBreadCrumbDataState',
  default: { title: '', breadcrumbs: [] },
});

const seoDataState = atom({
  key: 'seoDataState',
  default: null,
});

const topMenuDataState = atom({
  key: 'topMenuDataState',
  default: null,
});

const footerDataState = atom({
  key: 'footerDataState',
  default: null,
});

export {
  currentUserState,
  themeConfigState,
  seoDataState,
  topMenuDataState,
  footerDataState,
  travellerSideMenuOpenState,
  travellerBreadCrumbDataState,
  agentSideMenuOpenState,
  agentBreadCrumbDataState,
  signInDialogOpenState,
  pauseAnimationsState,
  newEnquiryDialogOpenState,
};
