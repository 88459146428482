import React from 'react';
import Box from '@material-ui/core/Box';
import RichText from '../components/RichText';
import { makeStyles } from '@material-ui/core/styles';
import { Link as GatsbyLink } from 'gatsby';
import Link from '@material-ui/core/Link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    paddingLeft: '3em',
    paddingRight: '3em',
    '& p': {
      lineHeight: '2em',
      marginBottom: 0,
    },
  },
  footerLink: {
    color: '#ffffff',
    '&:active': {
      color: '#ffffff',
    },
  },
  mailToLink: {
    color: '#ffffff',
    '&:active': {
      color: '#ffffff',
    },
  },
  seperator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default ({ data }) => {
  const classes = useStyles();

  if (!data) return null;

  const { background_color, text_color, copy_right_text, site_map } = data;
  return (
    <Box
      py={2}
      bgcolor={background_color}
      color={text_color}
      className={classes.root}
    >
      <Box mb={1}>
        <a href="mailto:hello@tripbuddi.com" className={classes.mailToLink}>
          hello@tripbuddi.com
        </a>
      </Box>
      {site_map.length > 0 && (
        <Box>
          {site_map.map((item, index) => {
            return (
              <>
                <Link
                  className={classes.footerLink}
                  component={AniLink}
                  fade
                  duration={1}
                  key={index}
                  to={
                    item.link._meta.uid != null ? `/${item.link._meta.uid}` : ''
                  }
                  variant="body2"
                  underline="hover"
                >
                  {item.label}
                </Link>
                {index < site_map.length - 1 && (
                  <span className={classes.seperator}>|</span>
                )}
              </>
            );
          })}
        </Box>
      )}
      <Box>
        <RichText render={copy_right_text} />
      </Box>
    </Box>
  );
};
