import React, { useState } from 'react';

export default defaultValue => {
  const [value, setValue] = useState(defaultValue);

  const handleUpdate = newValue => {
    if (newValue.target) {
      setValue(newValue.target.value);
    } else {
      setValue(newValue);
    }
  };

  return [value, setValue, handleUpdate];
};
