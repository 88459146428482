import produce from 'immer';

const forgotPasswordReducer = (draft, action) => {
  const { type, payload } = action;
  const { field, errorCode } = payload || {};
  const { email, fieldErrors } = draft;
  const emptyfieldErrors = {
    email: '',
  };

  switch (type) {
    case 'attemptForgotPassword':
      draft.loading = true;
      draft.hasError = false;
      draft.error = '';
      draft.fieldErrors = {
        email: '',
      };
      break;
    case 'fieldErrors':
      if (email.length === 0) {
        emptyfieldErrors.email =
          'You must provide an email to get new password';
      }
      draft.fieldErrors = emptyfieldErrors;
      draft.loading = false;
      draft.hasError = false;
      draft.error = '';
      break;
    case 'forgotPasswordSuccesful':
      draft.loading = false;
      draft.hasError = false;
      draft.error = '';
      draft.forgotPasswordSent = true;
      break;
    case 'forgotPasswordError':
      draft.email = '';
      draft.loading = false;
      draft.hasError = true;
      draft.error =
        errorCodeMappings[errorCode] || 'Error with forgot password request';
      draft.forgotPasswordSent = false;
      break;
    case 'updateField':
      if (field.value.length !== 0) fieldErrors[field.name] = '';
      draft[field.name] = field.value;
      draft.fieldErrors = fieldErrors;
      draft.hasError = false;
      draft.error = '';
      break;
    case 'resetField':
      draft[field.name] = '';
      break;
    default:
      break;
  }
  return draft;
};

export const initialState = {
  email: '',
  forgotPasswordSent: false,
  loading: false,
  hasError: false,
  error: '',
  fieldErrors: {
    email: '',
    password: '',
  },
};

const errorCodeMappings = {
  UserNotConfirmedException:
    'This account requires email confirmaion. Please check your email to verify your account.',
  PasswordResetRequiredException:
    'Your password needs to be reset. Please click on the forgot password link to reset your password.',
  NotAuthorizedException: 'Incorrect username or password. Please try again.',
  InvalidParameterException:
    'Cannot reset password for the user as there is no registered/verified email or phone_number',
  UserNotFoundException:
    'The username is not found. Please click on the Sign up link to create an account.',
};

export default produce(forgotPasswordReducer);
