import React, { useReducer } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import useStyles from './SignInForm.styles';
import signInReducer, { initialState } from './signInReducer';
import GoogleLogo from '../../../svg/GoogleLogo.svg';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import Typography from '@material-ui/core/Typography';

const SignInForm = ({ setScreenToShow }) => {
  const classes = useStyles();

  const [state, dispatch] = useReducer(signInReducer, initialState);

  const { email, password, loading, hasError, error, fieldErrors } = state;

  const handleFieldChange = e => {
    dispatch({ type: 'updateField', payload: { field: e.target } });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (email.length === 0 || password.length === 0) {
      dispatch({ type: 'fieldErrors' });
    } else {
      try {
        dispatch({ type: 'attemptSignIn' });
        await Auth.signIn(email, password);
        dispatch({ type: 'signInSuccessful' });
        navigate('/signedin');
      } catch (err) {
        console.error(err);
        dispatch({ type: 'signInError', payload: { errorCode: err.code } });
      }
    }
  };

  return (
    <>
      <Box>
        <Box px={2} pb={1}>
          <Box py={1}>
            <Button
              variant="outlined"
              color="default"
              className={classes.button}
              startIcon={<GoogleLogo />}
              fullWidth
              size="large"
              onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
            >
              Sign in with Google
            </Button>
          </Box>
          <Box py={1}>
            <Button
              variant="outlined"
              color="default"
              className={classes.button}
              startIcon={
                <FacebookIcon htmlColor="#648dae" style={{ fontSize: 28 }} />
              }
              fullWidth
              size="large"
              onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
            >
              Sign in with Facebook
            </Button>
          </Box>
          <Box
            textAlign="center"
            position="relative"
            className={classes.orLine}
          >
            <span>OR</span>
          </Box>
          <hr />
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              className={classes.input}
              variant="filled"
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleFieldChange}
              disabled={loading}
              error={!!fieldErrors.email}
              helperText={fieldErrors.email || null}
            />
            <TextField
              className={classes.input}
              variant="filled"
              margin="dense"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handleFieldChange}
              disabled={loading}
              error={!!fieldErrors.password}
              helperText={fieldErrors.password || null}
            />
            {hasError && (
              <Box my={1}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Box mt={2} mb={2}>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                id="submit"
                disabled={loading}
              >
                Sign In
              </Button>
            </Box>
          </form>
        </Box>
        {!loading && (
          <Box className={classes.bottomSection}>
            <Box textAlign="center" my={0} pb={2}>
              <Typography
                className={classes.navLink}
                variant="body2"
                onClick={() => {
                  setScreenToShow('signUp');
                }}
              >
                {"Don't have an account? Sign up"}
              </Typography>
            </Box>
            <Box textAlign="center" pb={2}>
              <Typography
                className={classes.navLink}
                variant="body2"
                onClick={() => {
                  setScreenToShow('forgotPassword');
                }}
              >
                Forgot password?
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SignInForm;
