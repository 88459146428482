import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link as PrismicLink, RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';
import { linkResolver } from '../utils/linkResolver';

const htmlSerializer = (type, element, content, children, index) => {
  // Generate links to Prismic Documents as <Link> components
  if (type === 'em') {
    return <em>{children}</em>;
  }
  if (type === 'b') {
    return <b>{children}</b>;
  }
  if (type === 'heading1') {
    return (
      <Typography variant="h1" component="h1" gutterBottom key={index}>
        {children}
      </Typography>
    );
  }
  if (type === 'heading2') {
    return (
      <Typography variant="h2" component="h2" gutterBottom key={index}>
        {children}
      </Typography>
    );
  }
  if (type === 'heading3') {
    return (
      <Typography variant="h3" component="h3" gutterBottom key={index}>
        {children}
      </Typography>
    );
  }
  if (type === 'heading4') {
    return (
      <Typography variant="h4" component="h4" gutterBottom key={index}>
        {children}
      </Typography>
    );
  }
  if (type === 'heading5') {
    return (
      <Typography variant="h5" component="h5" gutterBottom key={index}>
        {children}
      </Typography>
    );
  }
  if (type === 'heading6') {
    return (
      <Typography variant="h6" component="h6" gutterBottom key={index}>
        {children}
      </Typography>
    );
  }
  if (type === 'hyperlink') {
    let result = '';
    const url = PrismicLink.url(element.data, linkResolver);
    if (element.data.link_type === 'Document') {
      result = (
        <Link to={url} key={index}>
          {content}
        </Link>
      );
    } else {
      const target = element.data.target
        ? { target: element.data.target, rel: 'noopener' }
        : {};
      result = (
        <a href={url} {...target} key={index}>
          {content}
        </a>
      );
    }
    return result;
  }

  // If the image is also a link to a Prismic Document, it will return a <Link> component
  if (type === 'image') {
    let result = (
      <img
        src={element.url}
        alt={element.alt || ''}
        copyright={element.copyright || ''}
        key={index}
      />
    );

    if (element.linkTo) {
      const url = PrismicLink.url(element.linkTo, linkResolver);

      if (element.linkTo.link_type === 'Document') {
        result = (
          <Link to={url} key={index}>
            {result}
          </Link>
        );
      } else {
        const target = element.linkTo.target
          ? { target: element.linkTo.target, rel: 'noopener' }
          : {};
        result = (
          <a href={url} {...target} key={index}>
            {result}
          </a>
        );
      }
    }
    const wrapperClassList = [element.label || '', 'block-img'];
    result = (
      <p className={wrapperClassList.join(' ')} key={index}>
        {result}
      </p>
    );
    return result;
  }

  // Return null to stick with the default behavior for everything else
  return null;
};

const RichTextCustom = ({ render, renderAsText }) => {
  return (
    <RichText
      render={render}
      linkResolver={linkResolver}
      htmlSerializer={htmlSerializer}
      renderAsText={renderAsText}
    />
  );
};

export default RichTextCustom;
