module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":true,"disableMinification":true},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"tripbuddi","accessToken":"MC5YclpDWVJBQUFQdVpwTXN5.Ue-_vQIcKu-_ve-_vXLvv712Re-_vVPvv73vv70377-977-977-9enfvv71SA--_vSNN77-977-9O--_ve-_vQ","path":"/preview","previews":true,"pages":[{"type":"Page","match":"/:uid","path":"/page-preview","component":"/codebuild/output/src127108602/src/tripbuddi/src/website/templates/Page.jsx"}],"sharpKeys":[{},"backgroundImage"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/codebuild/output/src127108602/src/tripbuddi/src/layouts/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-147959615-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tripbuddi","short_name":"Tripbuddi","description":"Tripbuddi - Your best friend to help you enjoy travel more than ever","start_url":"","background_color":"#EBEDF2","theme_color":"#3D63AE","display":"standalone","icon":"src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8a679bf866a57f5f55b8bae96925a3d8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
