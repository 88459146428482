import Amplify from 'aws-amplify';
import config from './src/aws-exports';

const runConfig = () => {
  const isLocalhost = Boolean(
    (window && window.location.hostname === 'localhost') ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  // by default, say it's localhost
  const oauth = {
    domain: 'tripbuddi-master.auth.ap-southeast-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'http://localhost:8000/signedin/',
    redirectSignOut: 'http://localhost:8000/signedout/',
    responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
  };

  // if not, update the URLs
  if (!isLocalhost) {
    oauth.redirectSignIn = 'https://www.tripbuddi.com/signedin/';
    oauth.redirectSignOut = 'https://www.tripbuddi.com/signedout/';
  }

  // copy the constant config (aws-exports.js) because config is read only.
  var configUpdate = config;
  // update the configUpdate constant with the good URLs
  configUpdate.oauth = oauth;
  Amplify.configure(configUpdate);
};

export default runConfig;
